<template>
  <div class="container">
    <b-card class="p-3">
      <h3 class="mb-4">Login</h3>
      <b-form @submit="login" @reset="onReset" v-if="show">
        <b-form-group id="username-group" label="Username:" label-for="username">
          <b-form-input
            id="username"
            v-model="form.username"
            type="text"
            placeholder="Enter username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="password-group" label="Password:" label-for="password">
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            placeholder="Enter password"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="remeberFormGroup">
          <b-form-checkbox-group v-model="form.checked" id="rememberChecked">
            <b-form-checkbox value="remember">Remember me</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <div class="d-flex !important justify-content-between !important">
          <div>
            <b-button type="reset" variant="danger" class="custom-red-button">Reset</b-button>&nbsp;
            <b-button type="submit" variant="primary">Login</b-button>
          </div>
          <div>
          <a href="#" v-b-modal.modal1>Forgot Password</a>
        </div>
        </div>
      </b-form>
    </b-card>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <MessageBox ref="messageBox" :message="message" />
  </div>
</template>

<script>
import axios from '@/axios.js';
import { mapActions } from 'vuex';
import MessageBox from './MessageBox.vue';

export default {
  name: 'UserLogin',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      show: true,
      visitorIdentifier: '',
      errorMessage: '',
      message: ''  // Add the missing message property
    };
  },
  components: {
    MessageBox
  },
  created() {
    this.visitorUniqueId = this.generateRandomString(10);
    this.fetchVisitorIdentifier();
  },
  methods: {
    ...mapActions(['getCookie']),

    async fetchVisitorIdentifier() {
      this.visitorIdentifier = await this.getCookie('visitorIdentifier');
    },
    async login(event) {
      event.preventDefault()
      try {
        const params = new URLSearchParams();
        params.append('username', this.form.username);
        params.append('password', this.form.password);

        if (!this.visitorIdentifier) {
          this.setCookie('visitorIdentifier', this.visitorUniqueId);
          this.visitorIdentifier = this.visitorUniqueId;
        } 

        params.append('track_id', this.visitorIdentifier);

        const response = await axios.post('/authorization/login', params);
        this.setCookie('registrationEmail', this.form.username)
        this.$router.push('/welcome');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = error.response.data.detail;
          console.log(this.errorMessage);
        } else {
          console.error(error);
        }
      }
    },
    onReset(event) {
      event.preventDefault()
      this.form.username = ''
      this.form.password = ''
    },
    setCookie(key, email) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      const cookieString = `${key}=${email}; expires=${expirationDate.toUTCString()}; path=/`;
      document.cookie = cookieString;
    },
    generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    register() {
      this.$router.push('/register');
    },
    reset() {
      this.$router.push('/reset-password');
    }
  }
};
</script>

<style scoped>
/* Ensure form takes up the necessary space */
.login-form {
  position: relative;
  padding-bottom: 50px; /* Space for buttons */
}

</style>

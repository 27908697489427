<template>
  <div>
    <MessageBox ref="messageBox" :message="message" />
    <div class="container">
      <h2>Register</h2>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="text" id="email" v-model="email" class="form-control">
        </div>
        <button type="submit" class="btn btn-primary">Register</button>
      </form>
    </div>
  </div>
  </template>
  
<script>
  import axios from '@/axios.js';
  import MessageBox from './MessageBox.vue';
  
  export default {
    name: 'UserRegister',
    components: {
      MessageBox
    },
    data() {
      return {
        email: '',
        message: ''
      };
    },
    methods: {
      async register() {
        try {
          const response = await axios.post('/authorization/verify', {
            email: [this.email],
          });
          this.setEmailCookie(this.email);
          this.message = `We've sent a verification email to ${this.email}`
          // this.message = 'Registration email sent successfully!'
          
          this.$refs.messageBox.show();
          this.$router.push({
            name: 'EmailSentNotify',
            query: { registerEmail: this.email }
        });
        } catch (error) {
          console.error(error);
          this.message = 'Failed to send registration email.';
          this.$refs.messageBox.show();
        }
      },
      setEmailCookie(email) {
        // Set the cookie to expire in 7 days
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);

        // Create the cookie string
        const cookieString = `registrationEmail=${email}; expires=${expirationDate.toUTCString()}; path=/`;

        // Set the cookie
        document.cookie = cookieString;
      }
    }
  };
  </script>
  
<template>
<div class="container">
    <b-card class="p-3">
        <h2>Add New Medicine to {{  this.selectedOrganisation.name  }} stock</h2>
        <b-form @submit.prevent="submitForm">
        <b-form-group
            label="Name:"
            label-for="name"
            description="Enter the medicine name"
        >
            <b-form-input
            id="name"
            v-model="medicine.name"
            required
            placeholder="Enter medicine name"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Composition:"
            label-for="composition"
            description="Enter the medicine composition"
        >
            <b-form-input
            id="composition"
            v-model="medicine.composition"
            required
            placeholder="Enter composition"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Manufacturer:"
            label-for="manufacturer"
            description="Enter the manufacturer name"
        >
            <b-form-input
            id="manufacturer"
            v-model="medicine.manufacturer"
            required
            placeholder="Enter manufacturer"
            ></b-form-input>
        </b-form-group>

        <label for="expiry_date">Expiry Date:</label><br />
        <input type="date" id="expiry_date" v-model="medicine.expiry_date" />
        <hr>
        <b-row>
        <b-col>
            <b-form-group label="Is for Adult:">
            <b-form-checkbox v-model="medicine.is_for_adult" switch>
                {{ medicine.is_for_adult ? 'Yes' : 'No' }}
            </b-form-checkbox>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="In Stock:">
            <b-form-checkbox v-model="medicine.available_stock" switch>
                {{ medicine.available_stock ? 'Yes' : 'No' }}
            </b-form-checkbox>
            </b-form-group>
        </b-col>
        </b-row>

        <!-- <b-form-input type="hidden" v-model="orgId"></b-form-input> -->
            <div class="text-right">
                <b-button type="submit" variant="primary" class="mt-3">Submit</b-button>
            </div>
        </b-form>
    </b-card>
</div>
</template>

<script>
import axios from '@/axios.js';
import { mapState, mapActions, mapGetters } from 'vuex';


export default {
name: 'StockMedicine',

data() {
    return {
        medicine: {
            name: '',
            composition: '',
            manufacturer: '',
            expiry_date: '',
            is_for_adult: true,
            available_stock: true,
            organisation_id: null
            
        }
    };
},
computed: {
    ...mapState({
        isLoggedIn: state => state.auth.isLoggedIn,
        organisation: state => state.organisation,
        selectedOrganisation: state => state.selectedOrganisation
    }),
    ...mapGetters(
            [
                'isLoggedIn',
                'organisation',
            ]
        ),
},
created() {
    this.init();
},
async mounted() {
        // Check if there are route query parameters for editing
        const { id, name, composition, manufacturer, is_for_adult, expiry_date } = this.$route.query;
        if (id) {
        this.isEditMode = false;
        this.medicine = {
            id,
            name,
            composition,
            manufacturer,
            is_for_adult: is_for_adult === "true",
            expiry_date
        };
    }
},
methods: {
    ...mapActions({
        setLoggedIn: 'auth/setLoggedIn'
    }),
    async checkUserAuthentication() {
    try {
        const response = await axios.get('/authorization/');
    } catch (error) {
        this.$router.push('/login');
        }
    },
    async init() {
        // InitalizeOrganisation is called when user selects organisation on welcome page.
        // it's basically retrieving the organsiation from getOrganisation which is reading from stored cookie
        await this.$store.dispatch('initializeOrganisation');
        this.medicine.organisation_id = this.organisation.id;
    },
    async submitForm() {
        if (this.medicine.expiry_date === "") {
            alert("Date input is empty");
            return
        }

        try {
            const orgMedicineResponse = await axios.get(`/medicine/organisation/${this.medicine.organisation_id}/`);
            if (orgMedicineResponse.status === 200) {
                const matchingMedicine = orgMedicineResponse.data.find(med => 
                    // med.name === this.medicine.name || 
                    (med.name === this.medicine.name && med.expiry_date === this.medicine.expiry_date)
                );
                if (matchingMedicine) {
                    const msg = `${this.medicine.name} already exists! What would you like to do?`
                    const result = await this.$bvModal.msgBoxConfirm(msg, {
                        title: `${this.medicine.name} already Exists in stock.`,
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'Add to existing stock',
                        cancelVariant: 'outline-secondary',
                        cancelTitle: 'Cancel and edit name or expiry date',
                        centered: true
                    });

                    if (result) {
                        // User chose to add to existing stock
                        const updateResponse = await axios.post('/medicine/update/stock/',
                            {
                                id: matchingMedicine.id,
                                organisation_id: this.medicine.organisation_id,
                                add_stock: 1 // FIXME: Display numeric counter so user can add multiple units in stock.
                            });

                        if (updateResponse.status === 200) {
                            this.$bvToast.toast(`Successfully updated stock for ${this.medicine.name}`, {
                                title: 'Success',
                                variant: 'success',
                                solid: true
                            });
                            this.resetForm();
                            }
                        }
                    } else {
                        // Error fetching organisation medicines, fallback to adding new
                        await this.addNewMedicine();
                    }
                    this.$router.push("/medicines");
                }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                this.storeFormDataInCookie();
                this.$router.push('/login');
            } else {
                this.$bvToast.toast('Error processing medicine', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    },

    async addNewMedicine() {
        try {
            const response = await axios.post('/medicine/add/', this.medicine);
            if (response.status === 200) {
                this.$bvToast.toast(`Successfully added ${this.medicine.name}`, {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                });
                this.resetForm();
            } else {
                this.$bvToast.toast(`Error occurred. ${this.medicine.name} not added.`, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
            }
        } catch (error) {
            console.log(error);
            this.$bvToast.toast('Error adding medicine', {
                title: 'Error',
                variant: 'danger',
                solid: true
            });
        }
    },
    resetForm() {
        this.medicine = {
            name: '',
            composition: '',
            manufacturer: '',
            expiry_date: null,
            is_for_adult: false,
            in_stock: true
        };
    },
    storeFormDataInCookie() {
    document.cookie = `medicineFormData=${JSON.stringify(this.medicine)}; path=/`;
    }
}
};
</script>

<style scoped>
.medicine-form {
max-width: 500px;
margin: 0 auto;
padding: 20px;
}

.custom-datepicker .b-form-datepicker-inner {
    background-color: rgba(200, 200, 200, 0.8) !important;
}

.custom-datepicker .b-calendar-grid-body .btn-light {
    background-color: transparent !important;
    color: #000 !important;
}

.custom-datepicker .b-calendar-grid-body .btn-light:hover,
.custom-datepicker .b-calendar-grid-body .btn-light:focus {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-datepicker .b-calendar-grid-body .btn-primary {
    background-color: #007bff !important;
    color: #fff !important;
}

.text-right {
    text-align: right;
}
</style>
import axios from 'axios';

// Function to determine the backend URL based on the hostname
function determineBackendUrl() {
    const hostname = window.location.hostname;

    if (hostname.includes('localhost')) {
        return 'http://localhost:8091/v1';
    } else if (hostname.includes('enlively.co.uk')) {
        return 'https://api.enlively.co.uk/v1';
    } else {
        return 'http://localhost:8091/v2'; // Default fallback
    }
}

// Set the base URL globally using the determined backend URL
axios.defaults.baseURL = determineBackendUrl();
axios.defaults.withCredentials = true; // Set withCredentials globally if required

export default axios;

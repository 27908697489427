<template>
    <div class="container">
      <h2>Choose a password to complete the registration for the email {{ email }}</h2>
      <h4>If you are seeing an email above different than the one you expected. Please <a href='/register'>click here</a> to register again.</h4>
      <form @submit.prevent="registerComplete">
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" class="form-control">
        </div>
        <div class="form-group">
          <label for="retype-password">Retype Password:</label>
          <input type="password" id="retype-password" v-model="confirmPassword" class="form-control">
        </div>
        <div v-if="!isPasswordValid" class="text-danger">
        Password must be at least 8 characters long, contain uppercase and lowercase letters, numbers, and special characters.
      </div>
      <button type="submit" class="btn btn-primary">Complete Registration</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from '@/axios.js';
  
  export default {
    name: 'UserRegisterComplete',
    data() {
      return {
        cookieEmail: '',
        urlEmail: '',
        selectedEmail: '',
        password: '',
        confirmPassword: '',
        registrationMessage: '',
        registrationMessageStyle: ''
    };
  },
  computed: {
    isPasswordValid() {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordRegex.test(this.password) && this.password === this.confirmPassword;
    }
  },
  emailMismatch() {
    return this.cookieEmail !== this.urlEmail;
  },
  email() {
    return this.selectedEmail || this.cookieEmail || this.urlEmail;
  },
  created() {
    this.initializeEmail();
  },
    methods: {
      initializeEmail() {
        // Get email from cookie
        const cookieEmail = this.getCookie('registrationEmail');

        // Get email from URL
        const urlParams = new URLSearchParams(window.location.search);
        const urlEmail = urlParams.get('email');

        // Use cookie email if available, otherwise use URL email
        this.email = cookieEmail || urlEmail || '';
      },
      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },
      setEmailCookie(email) {
        // Set the cookie to expire in 7 days
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);

        // Create the cookie string
        const cookieString = `registrationEmail=${email}; expires=${expirationDate.toUTCString()}; path=/`;

        // Set the cookie
        document.cookie = cookieString;
      },
      async registerComplete() {
        try {
          const response = await axios.post('/authorization/create', {
            email: this.email,
            password: this.password
          });
          this.setEmailCookie(this.email);
          if (response.status === 201) {
            this.registrationMessage = '<b><i style="color: green;">Registration completed successfully, please <a href="/login">login now</a>.</i></b>';
          } else {
            this.registrationMessage = '<b style="color: red;">Something bad has happened and registration has not been successful. Please try again later.</b>';
          }
          this.registrationMessageStyle = 'font-weight: bold;';
          this.$router.push('/login');
        } catch (error) { 
          console.error(error);
          this.registrationMessage = '<b style="color: red;">Something bad has happened and registration has not been successful. Please try again later.</b>';
          this.registrationMessageStyle = 'font-weight: bold;';
      }
      },
    }
  };
  </script>
  
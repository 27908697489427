<template>
    <div class="container">
    <h2>Welcome</h2>
    <hr>
        <div>
            <MessageBox ref="messagebox" />
        </div>
    <div v-if="isLoggedIn">
        <b-tabs  ref="tabs" pills card vertical v-if="organisations.length">
            <b-tab 
                v-for="(org, index) in organisations" 
                :key="org.id" 
                :title="org.name"
                :active="index === activeIndex"
                @click="selectOrg(org, index)"
                >
                <b-card-text>
                <h5>{{ org.category }}</h5>
                <ul>
                    <li>Add Staff Members</li>
                    <li><a href="#" @click.prevent="goToMedicine(org.name, org.id)">Add Medicine</a></li>
                    <li><a href="#" @click.prevent="goToViewMedicines()">View Medicines</a></li>
                </ul>
                </b-card-text>
            </b-tab>
            <b-tab title="Add New" @click.prevent="addNewOrganisation">
                <b-card-text>
                <a href="#" @click.prevent="addNewOrganisation">Click to add a new organisation</a>
                </b-card-text>
            </b-tab>
        </b-tabs>

        <div v-else-if="!loading">
            <p> {{ this.organisations_message }}</p>
            <p>Would you like to add one?</p>
            <button @click="addNewOrganisation" class="add-org-button">
                Add Organisation
            </button>
        </div>
    </div>
    <div v-else>
        <p class="text-danger">Not authenticated. Please login again.</p>
    </div>
</div>
</template>

<script>
import MessageBox from './MessageBox.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { encode } from 'base-64';

export default {
    name: 'WelcomeUser',
    components: {
        MessageBox
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.auth.isLoggedIn,
            organisation: state => state.organisation,
            organisations: state => state.organisations,
            selectedOrganisation: state => state.selectedOrganisation,
        }),
        ...mapGetters(
            [
                'isLoggedIn',
                'userEmail',
                'organisations',
                'organisation',
                'selectedOrganisation'
            ]
        )
        },
        watch: {
        selectedOrganisation(newOrg) {
            if (newOrg) {
            // Find the index of the selected organization in the organisations array
            const index = this.organisations.findIndex(org => org.id === newOrg.id);
            if (index !== -1) {
                // set the active tab to the new organization's index
                this.activeIndex = index;
            }
            }
        }
        },
    data() {
        return {
            error: null,
            loading: false,
            showDropdown: false,
            organisations_message: '',
            activeIndex: 0 // FIXME: This sets the UI to first tab, but default state is still different in vuex.
        };
    },
created() {
if (this.isLoggedIn) {
        this.$store.dispatch('fetchOrganisations');  // Fetch organisations via Vuex action
    }
// investigate why calling the line below fix the issue
this.isLoggedIn
},
methods: {
    ...mapActions({
        selectOrganisation: 'selectOrganisation',
        setCookie: 'setCookie'
    }),
    selectOrg(org, index) {
        this.setCookie({ orgName: org.name, orgId: org.id, index: index });
        this.activeIndex = index
    },
    getActiveTab() {
        const activeTab = this.$refs.tabs.$children.find(tab => tab.isActive);
        if (activeTab) {
            console.log('Active Tab:', activeTab.title);
        }
    },
    logout() {
        this.$store.dispatch('logoutAction');
        this.removeEmailCookie();
        this.$router.push('/login');
    },
    handleBackButton(event) {
        console.log('Back button clicked:', event);
        this.$refs.messagebox.show();
    },
    goToViewMedicines(){
        this.$router.push('/medicines');
    },
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    },
    addNewOrganisation() {
        this.$router.push('/add-organisation');
    },
    goToMedicine(orgName, orgId) {
        const combined = `${orgName}:${orgId}`;
        const hash = encode(combined);
        this.$router.push({
            name: 'StockMedicine',
            params: { org: hash }
        });
    },
    removeEmailCookie() {
        localStorage.removeItem('registrationEmail');
    },
async checkAuth() {
    try {
        await this.$store.dispatch('checkAuthentication');
        await this.$store.dispatch('fetchOrganisations');
        // The auth state will be updated automatically if the check is successful
    } catch (error) {
        console.error('Error checking authentication:', error);
    }
}
},
async mounted() {
    // check authentication when the component mounts
    await this.checkAuth();
    await this.$store.dispatch('initializeOrganisation');
    window.addEventListener('popstate', this.handleBackButton);
    // this.getActiveTab();
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handleBackButton);
    },
};
</script>

<style scoped>
.container {
    margin-top: 50px;
    position: relative;
}

.user-info {
    position: absolute;
    top: 0.5px; /* Adjust as necessary */
    right: 10px; /* Adjust as necessary */
    display: flex;
    cursor: hand;
}
.no-org-container {
    position: relative;
    min-height: 100px;
}
.add-org-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgb(41, 111, 243);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
/* Circle with first letter */
.user-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(41, 111, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

/* Dropdown arrow next to the circle */
.dropdown-arrow {
    margin-left: 10px;
    font-size: 16px;
    color: #333;
}

/* Dropdown menu hidden by default */
.dropdown-menu {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    display: none;
}

  /* Show dropdown when v-show is true */
.user-info .dropdown-menu{
    display: block;
}

  /* Style for email and logout link */
.dropdown-menu p {
    margin: 0;
    font-weight: bold;
    color: #333;
}

.dropdown-menu a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.dropdown-menu a:hover {
color: #0056b3;
text-decoration: underline;
}
</style>

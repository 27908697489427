import Vue from 'vue';
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue';
import router from './router';
import store from './store/store';
import './assets/custom.css';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(BootstrapVue);

// vue configuration object
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');

<template>
    <div class="container">
        <b-card class="p-3">
            <h2>Registration Email Sent</h2>
                <p>We've sent a verification email to <span class="email">{{ email }}</span>. Please check your inbox and follow the instructions to complete the registration process.</p>
                <div class="inbox-button">
                <a :href="inboxUrl" target="_blank">
                    <img :src="inboxButtonSrc" :alt="inboxButtonAlt" class="inbox-button-image"></a>
                    &nbsp;&nbsp;
                    <b-button size="lg" squared class="custom-red-button" href="/login">Login</b-button>
                </div>
        </b-card>
    </div>
</template>

<script>
    export default {
    name: 'RegistrationSuccess',
    data() {
        return {
        email: 'example@email.com',
        inboxButtonSrc: '',
        inboxButtonAlt: ''
        }
    },
    computed: {
        inboxUrl() {
            this.email = this.$route.query.registerEmail
            const emailProvider = this.getEmailProvider(this.email);
            switch (emailProvider) {
                case 'gmail':
                    return 'https://mail.google.com/mail/u/0/';
                case 'outlook':
                case 'hotmail':
                    return 'https://outlook.live.com/mail/';
                case 'yahoo':
                    return 'https://mail.yahoo.com/';
                case 'mail.com':
                    return 'https://www.mail.com/mail/';
                case 'icloud':
                    return 'https://www.icloud.com/mail/';
                case 'proton':
                    return 'https://mail.protonmail.com/';
                default:
                return '#';
            }
        }
    },
    mounted() {
        this.setInboxButtonDetails();
    },
    methods: {
        getEmailProvider(email) {
        const [, domain] = email.split('@');
        if (domain.includes('gmail')) {
            return 'gmail';
        } else if (domain.includes('outlook') || domain.includes('hotmail')) {
            return 'outlook';
        } else if (domain.includes('yahoo')) {
            return 'yahoo';
        } else if (domain.includes('yandex')) {
            return 'yandex';
        } else {
            return 'unknown';
        }
        },
        setInboxButtonDetails() {
        const emailProvider = this.getEmailProvider(this.email);
        switch (emailProvider) {
            case 'gmail':
                this.inboxButtonSrc = require('@/assets/images/gmail.png');
                this.inboxButtonAlt = 'Open Gmail';
            break;
            case 'outlook':
            case 'hotmail':
                this.inboxButtonSrc = require('@/assets/images/ms_outlook.png');
                this.inboxButtonAlt = 'Open Outlook';
            break;
            case 'yahoo':
                this.inboxButtonSrc = require('@/assets/images/yahoo_mail.png');
                this.inboxButtonAlt = 'Open Yahoo Mail';
            break;
            case 'mail.com':
                this.inboxButtonSrc = require('@/assets/images/mail_com.png');
                this.inboxButtonAlt = 'Open Mail.com';
            break;
            case 'icloud':
                this.inboxButtonSrc = require('@/assets/images/icloud_mail.png');
                this.inboxButtonAlt = 'Open iCloud Mail';
            break;
            case 'proton':
                this.inboxButtonSrc = require('@/assets/images/proton_mail.png');
                this.inboxButtonAlt = 'Open Proton Mail';
            default:
            this.inboxButtonSrc = 'https://example.com/generic-inbox-icon.png';
            this.inboxButtonAlt = 'Open Inbox';
        }
        }
    }
}
</script>

<style scoped>
    .registration-success {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    }

    h2 {
    color: #333;
    margin-bottom: 10px;
    }

    p {
    color: #666;
    font-size: 16px;
    }

    .email {
    font-weight: bold;
    color: #007bff;
    }

    .inbox-button {
    margin-top: 10px;
    }

    .inbox-button-image {
    height: 50px;
    }
</style>
<template>
    <div id="app">
        <header>
        <nav>
            <!-- Add navigation menu items here -->
        </nav>
        </header>

        <main>
        <section class="hero">
            <h1>Enlively's Life Long Healthcare</h1>
            <h2>Doctor Patient Engagement Platform</h2>
            <p>Clinicians, Doctors, and other healthcare staff all over the world use Enlively to make data-driven decisions,
                easy access to health care services and treatment patients need..</p>
        </section>

        <section class="features">
            <h2>We are the new wave of healthcare technology,</h2>
            <p>Enlivey strenghtens the delivery of services to both the care provider and patients by understanding the needs.</p>
        </section>

        <section class="benefits">
            <div class="benefit-item">
            <h3>Engage your patients in their healthcare.</h3>
            <p>Provide a world-class experience to each patient with smart appointment notifications, digital by default appointment letters and video consultations.</p>
            <p>Improve your DNA rates by up to 35% with the Enlively Doctor Patient Engagement Platform.</p>
            </div>
            <!-- Add more benefit items following the same structure -->
        </section>
        </main>

        <footer>
        <!-- Add footer content -->
        </footer>
    </div>
    </template>

    <script>
    export default {
    name: 'Home',
    // Add any necessary component logic here
    }
</script>

<style scoped>
/* Add your styles here to match the design of the DrDoctor website */
</style>
